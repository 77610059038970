import * as React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ChannelCards from "../components/shared/channelCards"
import Text from "../components/shared/text"
import AdSlot from "../components/shared/adSlot"

const FrontPage = ({ data }) => {
    if (!data) return null
    const document = data.prismicFrontPage.data

    return (
        <Layout head={document}>
            <SEO
                title={
                    document.seo_title.text ||
                    RichText.asText(document.page_title.raw)
                }
                description={document.seo_description?.text}
                ogImage={document.og_image?.url}
            />
            <div className="mx-auto px-6 pt-5 pb-10 max-w-7xl" id="content">
                {document.body.map(section => (
                    <>
                        {section.slice_type === "channel_cards" && (
                            <ChannelCards
                                data={section}
                                type="live"
                                key={section.slice_type}
                            />
                        )}
                        {section.slice_type === "text" && (
                            <Text data={section} key={section.slice_type} />
                        )}
                        {section.slice_type === "ad_slot" && (
                            <AdSlot
                                html={section.primary.code.text}
                                key={section.slice_type}
                            />
                        )}
                    </>
                ))}
            </div>
        </Layout>
    )
}

export default withPreview(FrontPage)

export const query = graphql`
    query FrontPageQuery {
        prismicFrontPage {
            data {
                page_title {
                    raw
                }
                page_description {
                    raw
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                og_image {
                    url
                }
                body {
                    ... on PrismicFrontPageBodyChannelCards {
                        items {
                            channel {
                                document {
                                    ... on PrismicChannel {
                                        uid
                                        data {
                                            name {
                                                text
                                            }
                                            image {
                                                localFile {
                                                    childImageSharp {
                                                        gatsbyImageData(
                                                            quality: 100
                                                            placeholder: BLURRED
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        slice_type
                    }
                    ... on PrismicFrontPageBodyText {
                        slice_type
                        primary {
                            content {
                                raw
                            }
                        }
                    }
                    ... on PrismicFrontPageBodyAdSlot {
                        slice_type
                        primary {
                            code {
                                text
                            }
                        }
                    }
                }
            }
        }
    }
`
