import * as React from "react"
import { RichText } from "prismic-reactjs"
import PrismicLink from "../prismicLink"

export default function Text({ data }) {
    return (
        <section className="pt-5 pb-10 prose prose-lg max-w-none text-gray-600">
            <RichText
                render={data.primary?.content.raw}
                serializeHyperlink={PrismicLink}
            />
        </section>
    )
}
