import * as React from "react"
import ChannelCard from "./channelCard"

export default function ChannelCards({ data, type }) {
    return (
        <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-12 mb-6">
            {data.items.map((item, i) => (
                <div key={i}>
                    <ChannelCard channel={item.channel.document} type={type} />
                </div>
            ))}
        </section>
    )
}
